import { Circle, GoogleMap, Marker, useJsApiLoader, } from "@react-google-maps/api";
import { isDesktop } from 'react-device-detect';

import Geocode from "react-geocode";
import moment from 'moment'

import { ReactComponent as Loader } from './assets/img/loader.svg';
import { IoCalendarOutline } from "react-icons/io5";
import { FaUserAltSlash, FaUserAlt } from "react-icons/fa";

import { useCallback, useEffect, useState } from "react";
import incidentTest from './test-incident.json';
import { getLatestIncident } from "./services/api";

import "./App.css";

const TESTING_MODE = false

const initialCoordinates = {
	lat: 35.7801739,
	lng: -8.6172541
};

//Idea to get news https://news.google.com/rss/search?q=mass-shooting+adelanto+mass&hl=en-US&gl=US&ceid=US%3Aen

const App = () => {
	const { isLoaded: mapScriptLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
	})
	Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
	Geocode.setLanguage("en");

	const [incidentLoadingState, setIncidentLoadingState] = useState("loading")
	const [videoId, setVideoId] = useState()
	const [incident, setIncident] = useState()
	const [coordinates, setCoordinates] = useState()
	const [mapIsReady, setMapIsReady] = useState(false)
	const [map, setMap] = useState()

	const onLoad = useCallback(function callback(map) {
		setMap(map)
	}, [])

	const onUnmount = useCallback(function callback(map) {
		setMap(null)
	}, [])

	async function getIncidentFromAWS() {
		setIncidentLoadingState("loading")

		if (!TESTING_MODE) {
			try {
				const latestIncident = await getLatestIncident();
				setIncidentLoadingState("fetched")
				setIncident(latestIncident)
			} catch (error) {
				setIncidentLoadingState("error")
			}
		} else {
			setTimeout(()=> {
				setIncidentLoadingState("fetched")
				setIncident(incidentTest)
			},1200)
		}
	}

	const getDaysAgo = () => {
		const m = moment(incident.date, "MMM D, YYYY");
		const today = moment().startOf('day');
		return Math.round(moment.duration(today - m).asDays());
	}

	// Get incident
	useEffect(() => {
		getIncidentFromAWS()
	}, [])

		// Get incident video
		useEffect(() => {
			if (incident && isDesktop){
				getIncidentVideo()
			}
		}, [incident])

	const getIncidentVideo = () => {
		const apiKey = process.env.REACT_APP_GOOGLE_YOUTUBE_API_KEY;
		const maxResults = 10;
		const m = moment(incident.date, "MMM D, YYYY");
		const searchTerm = `shooting ${incident.city} ${incident.state} ${m.format("MMMM YYYY")}`;
		console.log(searchTerm)

		const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=${maxResults}&q=${encodeURIComponent(searchTerm)}&key=${apiKey}&type=video&videoEmbeddable=true&order=date`;

		fetch(apiUrl)
			.then(response => response.json())
			.then(data => {
				if (data.items) {
					setVideoId(data.items[0]?.id?.videoId)
				}
			})
			.catch(error => {
				console.error(error);
			});
	}

	// Get coordinates
	useEffect(()=> {
		if (incident) {
			const fullAddress = incident.address + ", " + incident.city; //Lodi street 14062 Forestville, new york

			Geocode.fromAddress(fullAddress).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					setCoordinates({lat, lng})
				},
				(error) => {
					console.error(error);
				}
			);
		}
		
	},[incident])

	// Once we have coordinates => Hide overlay & Show map
	useEffect(()=> {
		if (coordinates && map) {
			setTimeout(()=> {
				setMapIsReady(true)
			},700)
			setTimeout(()=> {
				slowZoom(19)
			},200)
		}
	},[coordinates, map])

	const slowZoom = (zoomLevel) => {
		var i = map.getZoom();
		var direction = (i < zoomLevel) ? 0.06 : -0.06;
		var int = setInterval(function () {
			if (i >= zoomLevel) clearInterval(int);
			else {
				i += direction;
				map.setZoom(i);
			}
		}, 80);
	}
	
	const visitIncidenGVA = () => {
		window.open(`https://www.gunviolencearchive.org/incident/${incident.id}`, '_blank')
	}

	return (
		<div className="App">
			<div id="overlay-preload" className={mapIsReady ? "transparent": ""}>
				<div className="all-centered">
					{incidentLoadingState === "error" ? <div id="loading-text">We are under maintenance</div> :
						!mapIsReady && 
						<>
							<div id="loading-text">Locating latest mass shooting ...</div>
							<Loader/>
						</>
					}
				</div>
			</div>
			{incident && 
				<>
					<div id="place" className="horizontally-centered" style={{position: "absolute"}} >
						{incident.city + ", " + incident.state}<br></br>
						<span className="address">{incident.address}</span>
					</div>
					<a href={`https://www.gunviolencearchive.org/incident/${incident.id}`} target="_blank">
						<div id="people" className="horizontally-centered clickable">
							<span className="link-message">More details here (GunViolenceArchive)</span>
							{ incident.killed ? <div className="data"> <FaUserAltSlash fontSize={20} color="#d10000"/> <span>{incident.killed} Killed</span></div> : null }
							{ incident.injured ? <div className="data"> <FaUserAlt fontSize={15} color="#d14400"/> <span>{incident.injured} Injured</span></div>: null }
							<div className="data"> <IoCalendarOutline fontSize={20}/> <span>{getDaysAgo() ? getDaysAgo() === 1 ? "Yesterday" : getDaysAgo() + " days ago" : "Today"}</span></div>
						</div>
					</a>
				</>
			}
			{mapScriptLoaded && (
				<>
					{isDesktop && 
						<iframe 
							width="500" 
							height="280" 
							src={`https://www.youtube.com/embed/${videoId}?si=r3W_afGJJdvvypQR&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;cc_load_policy=1&amp;loop=1&amp;playlist=${videoId}`}
							title="YouTube video player" 
							frameborder="0" 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
							referrerpolicy="strict-origin-when-cross-origin" 
							allowfullscreen
						/>
					}
					<GoogleMap
						mapContainerClassName="map-container"
						center={coordinates || initialCoordinates}
						onLoad={onLoad}
						onUnmount={onUnmount}
						options={{ disableDefaultUI: true, mapTypeId: window.google.maps.MapTypeId.SATELLITE, zoom: 18, gestureHandling: 'none' }}
					>
						{coordinates && 
						<>
							<Circle
								center={coordinates}
								radius={15}
								options={{ strokeColor: "#a10303", strokeWeight: 2, fillOpacity: 0 }}
							/>
							<Circle
								center={coordinates}
								radius={5}
								options={{ fillColor: "#a10303", fillOpacity: 0.3, strokeWeight: 0 }}
							/>
							<Marker position={coordinates} className="clickable"/>
						</>
						}
					</GoogleMap>
				</>
			
			)}
		</div>)
};

export default App;